<template>
  <div class="container">
    <div class="home-banner">
      <swiper
        v-if="banners.length"
        ref="homeSwiperRef"
        class="home-banner__slider"
        :options="swiperOption"
      >
        <swiper-slide
          v-for="(banner, index) in banners"
          :key="index"
          class="home-banner__slider-slide"
        >
          <a
            :href="banner.attributes.link"
            class="home-banner__slide"
            style="border-radius: 10px; margin-top: 40px; display: block"
            :style="{
              backgroundImage: `url(${getBannerImage(banner)})`,
              backgroundColor: banner.attributes.left_side_bg_color,
            }"
          >
            <div class="home-banner__inner">
              <div
                class="home-banner__content"
                :style="{ color: banner.attributes.left_side_text_color }"
              >
                <h3 class="home-banner__title">
                  {{ banner.attributes.header }}
                </h3>
                <p class="home-banner__text">
                  {{ banner.attributes.sub_header }}
                </p>
              </div>
              <!-- <div class="home-banner__img">
                <img src="@/assets/img/home-banner/home-banner.png" alt="" />
              </div> -->
            </div>
            <div class="home-banner__button">
              <a :href="banner.attributes.link">{{ $t("more") }}</a>
            </div>
          </a>
        </swiper-slide>
      </swiper>

      <div class="home-banner__actions">
        <div class="home-banner__arrows">
          <button
            class="home-banner__prev"
            @click="prevSlide"
          >
            <img
              src="@/assets/img/icons/prev-arrow-gray.png"
              alt=""
            />
          </button>
          <button
            class="home-banner__next"
            @click="nextSlide"
          >
            <img
              src="@/assets/img/icons/next-arrow-gray.png"
              alt=""
            />
          </button>
        </div>
      </div>

      <div class="slider-pagination-wrapper">
        <div class="pagination__custom"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { getBanners } from "@/api"

export default {
  name: "HomeBanerSlider",
  data() {
    return {
      swiperOption: {
        autoplay: {
          delay: 7000,
        },
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        lazy: true,
        slideTo: 1,
        slidesPerView: 1,
        spaceBetween: 20,
        initialSlide: 0,
        centeredSlides: false,
        loop: true,
        responsive: true,
        allowTouchMove: true,
        focusOnSelect: false,
        breakpoints: {
          768: {
            allowTouchMove: false,
          },
        },
        pagination: {
          el: ".pagination__custom",
          clickable: true,
          renderBullet: function (index, className) {
            return `<div class="wrapper ${className}">
              <div class="pie spinner"></div>
              <div class="pie filler"></div>
              <div class="mask"></div>
            </div>`
          },
        },
      },
      banners: [],
    }
  },
  created() {
    getBanners(this.selectedCity.id, "main").then((banners) => {
      this.banners = banners
    })
  },
  watch: {
    selectedCity: {
      deep: true,
      handler() {
        getBanners(this.selectedCity.id, "main").then((banners) => {
          this.banners = banners
        })
      },
    },
  },

  computed: {
    swiper() {
      return this.$refs?.homeSwiperRef
    },
    activeSlide() {
      return this.swiper?.swiperInstance.activeIndex
    },
    ...mapState("cityModule", ["selectedCity"]),
  },

  methods: {
    prevSlide() {
      this.swiper?.swiperInstance.slidePrev()
    },
    nextSlide() {
      this.swiper.swiperInstance.slideNext()
    },
    getBannerImage(banner) {
      const placeHolderImageUrl =
        "https://placeholder.pics/svg/288x380/B7FF7D/6C6D73-79E858/no%20moblie%20image"
      if (this.$isMobile()) {
        return banner.attributes?.image_mob?.data?.attributes?.url
          ? this.$helpers.getAbsolutePath(banner.attributes?.image_mob?.data?.attributes?.url)
          : placeHolderImageUrl
      }
      return banner.attributes?.image?.data?.attributes?.url
        ? this.$helpers.getAbsolutePath(banner.attributes?.image?.data?.attributes?.url)
        : placeHolderImageUrl
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.slider-pagination-wrapper {
  display: none;
  text-align: center;
  margin: 15px 0;
}
@media (max-width: 450px) {
  .slider-pagination-wrapper {
    display: block;
  }
}
.hidden {
  display: none;
}
.home-banner__slider-slide {
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
}
</style>
